import { container } from '@k-mille/ui-components'
import Component from './project'

const intl = 'project'

const mapStore = (store, ownProps, { form }) => ({
  code: form('project').get('code'),
  onSubmit: () => store.dispatch({ type: '@@ui/BUTTON>SAVE>PROJECT' }),
})

export default container({
  intl,
  mapStore,
})(Component)
