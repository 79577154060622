export default {
  main: {
    display: 'block',
    cursor: 'pointer',
    position: 'relative',
  },

  edit: {
    backgroundColor: '#0006',
    color: 'white',
    padding: '0.2em',
    position: 'absolute',
    bottom: '0',
    right: '0',
  },

  img: {
    maxWidth: '10em',
    maxHeight: '10em',
  },

  input: {
    display: 'none',
  },
}
