import React from 'react'
import PropTypes from 'prop-types'
import { component } from '@k-mille/ui-components'
import styles from './references.styles'

import Clients from './clients'
import Project from './project'
import Users from './users'
import Profile from './profile'

const References = ({
  classes,
}) => (
  <div className={classes.main}>
    <Project />
    <Clients />
    <Users />
    <Profile />
  </div>
)

References.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default component({ styles })(References)
