import { types } from 'k-ramel'

export default {
  data: {
    clients: types.keyValue(),
    users: types.keyValue(),
  },
  ui: {
    uploadedLogo: types.object(),
  },
}
