import { container } from '@k-mille/ui-components'
import Component from './clients'

const intl = 'clients'

const mapStore = (store, ownProps, { form }) => ({
  clients: form.find(/client-.+/),
})

export default container({
  mapStore,
  intl,
})(Component)
