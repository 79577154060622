import { when } from 'k-ramel'
import {
  load,
  setClients,
  setProject,
  saveProject,
  setUsers,
  saveClient,
  savePassword,
  setUploadedLogo,
  setPasswordError,
  clearPasswordErrors,
  logout,
} from './references.reactions'

export default [
  when('@@krml/LISTENERS>ADDED>main')(load),
  when('@@graphql/clients>ENDED')(setClients),
  when('@@graphql/projects>ENDED')(setProject),
  when('@@graphql/users>ENDED')(setUsers),
  when('@@ui/BUTTON>SAVE>CLIENT')(saveClient),
  when('@@graphql/addClient>ENDED')(setClients),
  when('@@ui/BUTTON>SAVE>PROJECT')(saveProject),
  when('@@ui/BUTTON>PROFILE>CHANGE_PASSWORD')(savePassword),
  when('@@ui/PROJECT>LOGO>LOAD')(setUploadedLogo),
  when('@@graphql/replacePassword>FAILED')(setPasswordError),
  when('@@krf/UPDATE>UI_FORM>VALUES', ({ payload }) => payload['@@form-name'] === 'password')(clearPasswordErrors),
  when('@@graphql/replacePassword>ENDED')(logout),
]
