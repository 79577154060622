import React from 'react'
import PropTypes from 'prop-types'
import {
  component,
  Button,
  Card,
  Input,
} from '@k-mille/ui-components'
import styles from './profile.styles'

const Profile = ({
  classes,
  messages,
  name,
  firstName,
  email,
  roles,
}) => (
  <Card className={classes.main}>
    <Card.Header>
      {messages.title}
    </Card.Header>
    <Card.Content>
      {firstName}
      {' '}
      {name}
      {' - '}
      {email}
      {' - ['}
      {roles.join(', ')}
      {']'}
      <Input
        filled
        type="password"
        label="current password"
        name="currentPassword"
        formName="password"
        required
      />
      <Input
        filled
        type="password"
        label="new password"
        name="newPassword"
        formName="password"
        required
      />
    </Card.Content>
    <Card.Actions>
      <Button
        primary
        name="PROFILE>CHANGE_PASSWORD"
      >
        change password
      </Button>
    </Card.Actions>
  </Card>
)

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  name: PropTypes.string,
  firstName: PropTypes.string,
  email: PropTypes.string,
  roles: PropTypes.array,
}

Profile.defaultProps = {
  name: undefined,
  firstName: undefined,
  email: undefined,
  roles: [],
}

export default component({ styles })(Profile)
