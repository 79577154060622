export default {
  main: {
    margin: '2em auto',
    minWidth: '50%',
    maxWidth: '100%',

    '& > div': {
      margin: '2em 0',
    },
  },
}
