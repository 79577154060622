import { container } from '@k-mille/ui-components'
import Component from './references'
import listeners from './references.listeners'

const name = 'main'

export default container({
  name,
  isScreen: true,
  listeners,
})(Component)
