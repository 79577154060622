export default theme => ({
  main: {
    backgroundColor: ({ id }) => (id ? theme.background : theme.listBackgroundDark),
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },

  postalCode: {
    width: '4em',
  },
  input: {
    marginRight: '1em',
  },
})
