import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {
  component,
  Button,
  Input,
  List,
} from '@k-mille/ui-components'
import styles from './client.styles'

const getInputType = (gqlType) => {
  switch (gqlType) {
    case 'Float': return 'number'
    default: return 'text'
  }
}

const graphqlToInput = (prefix = '') => (field) => {
  const properties = {
    name: `${prefix}${field.replace(/:.*/, '')}`,
    placeholder: `${prefix}${field.replace(/:.*/, '')}`,
    label: `${prefix}${field.replace(/:.*/, '')}`,
    type: getInputType(field.replace(/.*: /, '').replace('!', '')),
  }

  if (field.includes('!')) properties.required = true

  return properties
}

const fields = [ // this are GQL types
  'name: String!',
  'address: String!',
  'postalCode: String!',
  'city: String!',
].map(graphqlToInput())

const Client = ({
  classes,
  id,
  onSubmit,
}) => (
  <List.Item
    className={classes.main}
    onSubmit={onSubmit}
  >
    {fields.map(field => (
      <Input
        className={cn(
          classes[field.name],
          classes.input,
        )}
        filled
        key={field.name}
        formName={id || 'client'}
        {...field}
      />
    ))}
    <List.Item.Actions>
      <Button
        type="submit"
        icon={id ? 'save' : 'add'}
      />
    </List.Item.Actions>
  </List.Item>
)

Client.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  id: PropTypes.string,
}

Client.defaultProps = {
  id: undefined,
}

export default component({ styles })(Client)
