import { container } from '@k-mille/ui-components'
import Component from './client'

const mapStore = (store, { id }) => ({
  onSubmit: () => store.dispatch({ type: '@@ui/BUTTON>SAVE>CLIENT', payload: { id } }),
})

export default container({
  mapStore,
})(Component)
