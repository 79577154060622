export default {
  main: {
  },

  content: {
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  info: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: '40em',
    justifyContent: 'space-around',
  },

  googleLogo: {
    maxHeight: '5em',
    maxWidth: '5em',
    marginTop: '1em',
  },

  input: {
    width: '12em',
    margin: '.5em',

    '& > input': {
      width: '100%',
    },
  },

  colors: {
    display: 'flex',
  },

  colorInput: {
    width: '5em',

    '& > input': {
      minHeight: '2em',
      height: '100%',
      padding: '.2em',
    },
  },

  googleLink: {
    textDecoration: 'none',
    color: 'black',
    alignSelf: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    border: '1px solid #c8c8c8',
    padding: '1em',
    maxWidth: '10em',
    fontSize: '0.8em',
    textAlign: 'center',
    boxShadow: '0px 0px 10px -9px black',

    '&:hover': {
      boxShadow: '0px 0px 10px -6px black',
    },
  },
}
