import React from 'react'
import PropTypes from 'prop-types'
import { component, Card, List } from '@k-mille/ui-components'
import Client from './client'
import styles from './clients.styles'

const Clients = ({
  classes,
  clients,
}) => (
  <Card>
    <Card.Header>
      Clients
    </Card.Header>
    <Card.Content className={classes.content}>
      <List>
        {clients.map(id => (<Client key={id} id={id} />))}
        <Client />
      </List>
    </Card.Content>
  </Card>
)

Clients.propTypes = {
  classes: PropTypes.object.isRequired,
  clients: PropTypes.arrayOf(PropTypes.string),
}

Clients.defaultProps = {
  clients: [],
}

export default component({ styles })(Clients)
