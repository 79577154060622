import { container } from '@k-mille/ui-components'
import Component from './profile'

const intl = 'profile'

const mapStore = (store, ownProps, { form }) => ({
  ...store.data.profile.info.get(),
  roles: store.data.profile.roles.get(),
  error: form('password').getErrors('password'),
})

export default container({
  intl,
  mapStore,
})(Component)
