import React from 'react'
import PropTypes from 'prop-types'
import { component, Card } from '@k-mille/ui-components'

const Users = ({
  messages,
  users,
}) => (
  <Card>
    <Card.Header>
      {messages.title}
    </Card.Header>
    <Card.Content>
      {users.map(({
        id,
        name,
        firstName,
        email,
      }) => (
        <div key={id}>
          {firstName}
          {' '}
          {name}
          {` - (${email})`}
        </div>
      ))}
    </Card.Content>
  </Card>
)

Users.propTypes = {
  messages: PropTypes.object.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
}

Users.defaultProps = {
  users: [],
}

export default component()(Users)
