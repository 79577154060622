import { container } from '@k-mille/ui-components'
import Component from './logo'

const mapStore = (store, ownProps, { form }) => {
  let src = form('project').get('logo')

  if (store.ui.uploadedLogo.isInitialized()) {
    src = store.ui.uploadedLogo.get().data
  }

  return {
    src,
    onFileSelected: (e) => {
      store.dispatch({ type: '@@ui/PROJECT>LOGO>LOAD', payload: e.target.files[0] })
    },
  }
}

export default container({ mapStore })(Component)
