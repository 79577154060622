import { container } from '@k-mille/ui-components'
import Component from './users'

const intl = 'users'

const mapStore = store => ({
  users: store.data.users.getAsArray(),
})

export default container({
  intl,
  mapStore,
})(Component)
