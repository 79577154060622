import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { component } from '@k-mille/ui-components'
import styles from './logo.styles'

const Logo = ({
  classes,
  src,
  alt,
  onFileSelected,
}) => {
  const fileRef = useRef(null)

  const onClick = () => {
    fileRef.current.click()
  }

  return (
    <div
      className={classes.main}
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyPress={onClick}
    >
      <div
        className={classes.edit}
      >
        edit
      </div>

      <img
        className={classes.img}
        src={src}
        alt={alt}
      />

      <input
        className={classes.input}
        type="file"
        ref={fileRef}
        accept="image/*"
        multiple={false}
        onChange={onFileSelected}
      />
    </div>
  )
}

Logo.propTypes = {
  classes: PropTypes.object.isRequired,
  src: PropTypes.string,
  alt: PropTypes.string,
  onFileSelected: PropTypes.func,
}

Logo.defaultProps = {
  src: undefined,
  alt: '',
  onFileSelected: undefined,
}

export default component({ styles })(Logo)
